<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      v-if="!loading"
      class="content-wrapper"
    >
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ user.user.name }} <small class="d-block">{{ user.convo.title }}</small>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <a
            class="btn btn-icon btn-secondary me-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Tornar al dashboard"
            @click="$router.push({name: 'university.dashboard'})"
          ><i data-feather="chevron-left" /></a>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="tab-content">
          <!-- ACCEPTANCE DOC -->
          <Document
            v-if="showTab === 'tab-acceptance'"
            tab-id="tab-acceptance"
            :title="'Convocatòria ICREA Acadèmia ' + user.convo.year"
            :sub-title="'Document d’Acceptació'"
            :user="user"
            :document="user.acceptanceDocument"
            :active="showTab === 'tab-acceptance'"
          />
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      id="loading-bg"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Document from './Components/Document.vue'

export default {
  components: {
    Document,
  },
  data() {
    return {
      showTab: 'tab-acceptance',
      filterInfoSheet: 1,
      extension: {
        extension: null,
        reason: null,
      },
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      user: 'academyAwarded/item',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    await this.$store.dispatch('academyAwarded/fetchUser', this.$route.params.id)
    this.loading = false
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 200)
  },
}
</script>
